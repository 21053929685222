import { useStaticQuery, graphql } from "gatsby"

const usePageData = () => {
  const data = useStaticQuery(graphql`
  {
    allDataYaml(filter: { codePage: { eq: "sustainability" } }) {
      edges {
        node {
          ...fragmentSustainabilityPage
        }
      }
    }
  }
`)

  if (!data) return null

  return data.allDataYaml.edges[0].node
}

export default usePageData
