import React from 'react'
import SEO from "components/seo"

import Heading from "components/Heading"
import Quote from "components/Quote"
import ColumnImage from "components/ColumnImage"
import { motion } from "framer-motion"
import { motionVariants, heroTextVariants } from "utils/motion.utils"
import Img from "components/Img"
import Interweave from "interweave"
import imgSustainImage1 from "images/pageSustain--image-1.jpg"
import imgSustainImage2 from "images/pageSustain--image-2.jpg"
import imgSustainImage3 from "images/pageSustain--image-3.jpg"
import imgSustainImage4 from "images/pageSustain--image-4.jpg"
import imgSustainImage5 from "images/pageSustain--image-5.jpg"
import imgSustainImage6 from "images/pageSustain--image-6.jpg"


const ViewSustainability = ({pageData}) => {
  const {
    page,
    heading,
    testimonial,
    contentBlock1,
    contentBlock2,
    pillarsHeading,
    pillarColumn
  } = pageData;
  
  return (
    <React.Fragment>
      <SEO title={page} />
      <div className="max-container real-hero wrapper--sustain--hero">
        <motion.div className="container hero-container">
          <div className="hero-column is-relative hero-column--left has-one-row">
            <motion.div
              variants={motionVariants}
              initial="hiddenFromLeft"
              animate={"fadeFromLeft"}
              className="element-motion"
            >
              <Img
                className="hero-image-wrapper"
                name="heroSustainabilityImage1"
                isFluid={true}
              />
            </motion.div>
            <div className="hero-text">
              <motion.div
                initial={"hidden"}
                animate={"visible"}
                variants={heroTextVariants}
                className="hero-text-animation"
              >
                <Heading className="is-heading">
                  {heading}
                </Heading>
              </motion.div>
            </div>
          </div>
          <div className="hero-column hero-column--right has-two-row">
            <motion.div
              variants={motionVariants}
              animate={"fadeFromRight"}
              initial="hiddenFromRight"
              className="element-motion"
            >
              <Img
                className="hero-image-wrapper"
                name="heroSustainabilityImage2"
                isFluid={true}
              />
            </motion.div>
            <motion.div
              variants={motionVariants}
              initial="hiddenFromRight"
              animate={"fadeFromRight"}
              className="element-motion"
            >
              <Img
                className="hero-image-wrapper"
                name="heroSustainabilityImage3"
                isFluid={true}
              />
            </motion.div>
          </div>
        </motion.div>
      </div>
      <div className="max-container wrapper--sustain--quote bg-grey max-quote">
        <Quote>
          <Interweave content={testimonial} />
        </Quote>
      </div>
      <div className="max-container wrapper--sustain--content-1">
        <div className="container max-1048 container-2columns">
          <div className="sustain-col-left">
            <img
              src={imgSustainImage1}
              alt="For a better life for future generations"
            />
          </div>
          <div className="sustain-col-right">
            <Interweave content={contentBlock1} />
          </div>
        </div>
      </div>
      <div className="max-container wrapper--sustain--content-2">
        <div className="container max-1048 container-2columns is-tablet-up-reverse">
          <div className="sustain-col-left">
            <img
              src={imgSustainImage2}
              alt="For a better life for future generations"
            />
          </div>
          <div className="sustain-col-right">
            <Heading>
              {contentBlock2.title}
            </Heading>
            <Interweave content={contentBlock2.content} />
          </div>
        </div>
      </div>
      <div className="max-container wrapper--sustain--pillars">
        <div className="container">
          <Heading>
            <Interweave content={pillarsHeading} />
          </Heading>
          <div className="columns sustain-pillar-container">
            <div className="column is-3">
              <ColumnImage src={imgSustainImage3} alt={pillarColumn.ethical}>
                <Interweave content={pillarColumn.ethical} />
              </ColumnImage>
            </div>
            <div className="column is-3">
              <ColumnImage src={imgSustainImage4} alt={pillarColumn.environment}>
                {pillarColumn.environment}
              </ColumnImage>
            </div>
            <div className="column is-3">
              <ColumnImage src={imgSustainImage5} alt={pillarColumn.community}>
                {pillarColumn.community}
              </ColumnImage>
            </div>
            <div className="column is-3">
              <ColumnImage src={imgSustainImage6} alt={pillarColumn.workplace}>
                {pillarColumn.workplace}
              </ColumnImage>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ViewSustainability